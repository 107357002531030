import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import GlideCarousel from 'common/src/components/GlideCarousel';
import { PrevButton, NextButton } from '../portfolio.style';
import { SwiperSlide } from 'swiper/react';
import {
  TestimonialWrapper,
  TestimonialItem,
  TestimonialThumb,
} from './testimonial.style';

const TestimonialSection = ({
  sectionWrapper,
  secTitleWrapper,
  secTitle,
  secDescription,
  reviewStyle,
  nameStyle,
  designationStyle,
}) => {
  const Data = useStaticQuery(graphql`
    {
      portfolioJson {
        TESTIMONIAL {
          name
          designation
          review
          organization
          organizationURL
          image {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
  `);

  //Carousel Options
  const carouselOptions = {
    type: 'carousel',
    autoplay: {
      delay: 6000,
    },
    slidesPerView: 3,
    spaceBetween: 28,
    speed: 800,
    breakpoints: {
      990: {
        slidesPerView: 3,
      },
      767: {
        slidesPerView: 2,
      },
      0: {
        slidesPerView: 1,
      }
    },
  };

  return (
    <Box {...sectionWrapper} as="section" id="testimonial-section">
      <Container noGutter mobileGutter width="1200px">
        <Box {...secTitleWrapper}>
          <Heading {...secTitle} content="What My Clients Say" />
          <Text
            {...secDescription}
            content="Strive not to be a success, but rather to be of value. Albert Einstein"
          />
        </Box>
        <TestimonialWrapper>
          <GlideCarousel
            carouselSelector="testimonial-carousel"
            options={carouselOptions}
            prevButton={
              <PrevButton>
                <span />
              </PrevButton>
            }
            nextButton={
              <NextButton>
                <span />
              </NextButton>
            }
          >
            <>
              {Data.portfolioJson.TESTIMONIAL.map((item, index) => (
                <SwiperSlide key={`testimonial-item-${index}`}>
                  <TestimonialItem>
                    <Text {...reviewStyle} content={item.review} />
                    <Box
                      style={{
                        position: 'absolute',
                        bottom: '20px',
                        width: '100%',
                      }}
                    >
                      <TestimonialThumb>
                        <GatsbyImage
                          image={item.image.childImageSharp.gatsbyImageData}
                          alt={`testimonial-avatar-${index + 1}`}
                        />
                      </TestimonialThumb>
                      <Heading as="h3" content={item.name} {...nameStyle} />
                      <Text
                        as="span"
                        content={item.designation}
                        {...designationStyle}
                      />
                      <a
                        href={item.organizationURL || '#'}
                        className="reviewer_org"
                      >
                        {item.organization}
                      </a>
                    </Box>
                  </TestimonialItem>
                </SwiperSlide>
              ))}
            </>
          </GlideCarousel>
        </TestimonialWrapper>
      </Container>
    </Box>
  );
};

TestimonialSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  reviewStyle: PropTypes.object,
  nameStyle: PropTypes.object,
  designationStyle: PropTypes.object,
};

TestimonialSection.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '80px', '100px', '110px', '150px'],
    pb: '50px',
    backgroundColor: '#f9f9f9',
  },
  secTitleWrapper: {
    mb: ['90px', '90px', '50px', '50px', '50px'],
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '700',
    color: '#302b4e',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '20px'],
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    width: '530px',
    maxWidth: '100%',
    mb: '0',
  },
  reviewStyle: {
    fontSize: '16px',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '30px',
  },
  nameStyle: {
    fontSize: '16px',
    color: '#302b4e',
    fontWeight: '600',
    mb: '0',
    marginLeft: '60px',
    paddingTop: '7px',
  },
  designationStyle: {
    fontSize: '14px',
    color: '#43414e',
    mb: '0',
    marginLeft: '5px',
  },
};

export default TestimonialSection;
