import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Seo from '../components/seo';
import Helmet from 'react-helmet';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/Portfolio/portfolio.style';
import { graphql } from 'gatsby';
import { portfolioTheme } from 'common/src/theme/portfolio';
import { ResetCSS } from 'common/src/assets/css/style';
import Accordion from '../components/Accordion.js';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import Footer from '../containers/Portfolio/Footer';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Navbar from '../containers/Portfolio/Navbar';
import Sticky from 'react-stickynode';
import styled, { ThemeProvider } from 'styled-components';
import TestimonialSection from '../containers/Portfolio/Testimonial';
import ContactSection from '../containers/Portfolio/Contact';

const HeroWrapper = styled('div')`
  height: 70vh;
  @media (max-height: 500px) {
    height: 100vh;
  }
`;

const HeroContentWrapper = styled('div')`
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 16px;

  @media (min-width: 800px) {
    padding: 100px;
  }
`;

const HeroTextWrapper = styled('div')`
  color: white;
  font-size: 1.5rem;
  text-align: center;
  max-width: 1200px;
  margin: auto;

  a {
    color: #32abdf;
    font-weight: 500;
  }
`;

const InfoSectionWrapper = styled('div')`
  background: #fff;
  padding: 64px 10%;
  font-size: 18px;
  font-family: 'roboto', sans-serif;
  a {
    color: #32abdf;
    font-weight: 500;
  }
`;

const InfoSectionList = styled('ul')``;

const InfoSectionItem = styled('li')`
  margin: 32px 0;
  color: ##2f2c4c;
`;

const InfoSectionHeading = styled('h3')`
  color: #56aada;
  font-size: 24px;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
`;

const InfoMainHeading = styled('h2')`
  color: #2f2c4c;
  font-size: 30px;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
`;

const AccordionWrapper = styled('div')`
  background-color: #fff;
  padding: 32px 10%;
`;

const BottomWrapper = styled('div')`
  background-color: #f9f9f9;
  width: 100%;
`;

const BottomContent = styled('div')`
  padding: 100px 0;

  @media (min-width: 1400px) {
    padding: 100px 10%;
  }
`;

const CaseStudyPromoWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

const CaseStudyPromoCard = styled(AniLink)`
  display: flex;
  width: 30%;
  background-color: white;
  padding: 16px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: space-between;
  height: 14vw;
  min-height: 200px;
  min-width: 350px;
  box-shadow: 0px 8px 30px RGB(46, 45, 74);
  margin: 16px auto !important;

  & > div {
    &.gatsby-image-wrapper {
      background-color: ${props =>
          props.imageBackgroundColor ? props.imageBackgroundColor : '#FFFFFF'};
    }
  }

  & + & {
    margin-left: 32px;
  }
`;

const PromoCardText = styled('div')`
  color: black;
  background: white;
  font-size: 14px;
  font-family: 'roboto', sans-serif;
  font-weight: 300;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: skyblue;
    outline: 1px solid slategrey;
  }
`;

const PromoCardTitle = styled('span')`
  color: black;
  font-weight: bold;
  font-size: 16px;
`;

const PromoCardInfoWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  width: 50%;
`;

const PromoCardCTA = styled('div')`
  background: #56aada;
  display: block;
  padding: 8px;
  color: white;
  border-radius: 15px;
  text-align: center;
`;

const CTAHeading = styled('h2')`
  font-size: 32px;
  text-align: center;
  font-weight: 400;
  line-height: 1.5;
  padding: 32px 0;
  color: #2f2c4c;
`;

const StyledContactSection = styled(ContactSection)`
  background-color: #fff;
`;

const FlutterPage = ({ data }) => {
  return (
    <ThemeProvider theme={portfolioTheme}>
      <Fragment>
        <Seo
          title={'Why work with Flutter - Almog Development Studio FlutterDev'}
          description={
            'Why you should be using Google Flutter for mobile, desktop, web, and embedded devices. Almog Development Studio provides Flutter development'
          }
          keywords={[
            'why google flutter, flutter vs react native, flutterdev, almog development, flutter development agency, flutter development studio, flutter consulting',
          ]}
        />
        <Helmet>
          <meta
            property="og:image"
            content={'data.seoImage.childImageSharp.fluid.src'}
          />
          <meta
            property="og:image:secure"
            content={'data.seoImage.childImageSharp.fluid.src'}
          />
          <meta
            property="og:url"
            content={'https://www.almog.io/portfolio/why-gogole-flutter/'}
          />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content={
              'Why work with Flutter - Almog Development Studio FlutterDev'
            }
          />
          <meta
            property="og:description"
            content={
              'Why you should be using Google Flutter for mobile, desktop, web, and embedded devices. Almog Development Studio provides Flutter development'
            }
          />
        </Helmet>
        <ContentWrapper style={{ fontFamily: 'Raleway' }}>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <ResetCSS />
          <GlobalStyle />
          <HeroWrapper>
            <GatsbyImage
              objectFit="cover"
              style={{
                zIndex: -1,
                width: '100%',
                height: 'inherit',
                position: 'absolute',
              }}
              image={getImage(data.heroBackground)}
              alt=""
            >
              {' '}
            </GatsbyImage>
            <HeroContentWrapper>
              <HeroTextWrapper>
                <div style={{ display: 'inline-flex' }}>
                  <GatsbyImage
                    style={{
                      width: '300px',
                    }}
                    image={getImage(data.flutterIcon)}
                    alt=""
                  />
                </div>
                <p className="full-text">
                  Flutter is Google's UI toolkit for building beautiful,
                  natively compiled applications for{' '}
                  <a
                    href="https://flutter.dev/docs"
                    target="_blank"
                    rel="noreferrer"
                  >
                    mobile
                  </a>
                  ,{' '}
                  <a
                    href="https://flutter.dev/docs"
                    target="_blank"
                    rel="noreferrer"
                  >
                    web
                  </a>
                  ,{' '}
                  <a
                    href="https://flutter.dev/docs"
                    target="_blank"
                    rel="noreferrer"
                  >
                    desktop
                  </a>
                  , and{' '}
                  <a
                    href="https://flutter.dev/docs"
                    target="_blank"
                    rel="noreferrer"
                  >
                    embedded
                  </a>{' '}
                  devices from a single codebase.
                </p>
              </HeroTextWrapper>
            </HeroContentWrapper>
          </HeroWrapper>
          <InfoSectionWrapper>
            <InfoMainHeading>Why Google Flutter?</InfoMainHeading>
            <InfoSectionList>
              <InfoSectionHeading>Faster Development</InfoSectionHeading>
              <InfoSectionItem>
                Flutter lets you develop native apps on iOS, Android, Mac,
                Linux, Windows, embedded devices, smart devices, and Web from a
                single codebase. No need to hire a different developer per
                platform, saving you time and cost. While providing a rich set
                of fully customizable widgets to build native interfaces in
                minutes.
              </InfoSectionItem>
              <InfoSectionHeading>
                Expressive and Flexible UI
              </InfoSectionHeading>
              <InfoSectionItem>
                Quickly ship features with a focus on native end-user
                experiences. The layered architecture allows for complete
                customization, which results in high-speed rendering and
                expressive and flexible designs. Flutter uses the Skia Graphics
                Engine, a well-supported high-performance 2D graphics engine
                helping render UI with amazing native end-user experience.
              </InfoSectionItem>
              <InfoSectionHeading>Native Performance</InfoSectionHeading>
              <InfoSectionItem>
                Flutter’s widgets incorporate all critical platform differences
                such as scrolling, navigation, icons, and fonts, and your
                Flutter code is compiled to native ARM machine code using{' '}
                <a
                  href="https://dart.dev/overview#platform"
                  target="_blank"
                  rel="noreferrer"
                >
                  Dart’s native
                </a>
                . By using native compilers, Flutter compiles low-level C and
                C++, providing the best native performance, unlike React-Native,
                which uses a bridge. compilers.
              </InfoSectionItem>
              <InfoSectionHeading>
                Used by Companies Big and Small
              </InfoSectionHeading>
              <InfoSectionItem>
                Flutter is growing rapidly and is currently being used by some
                of the from big companies to startups providing products and
                services to the consumer space, business to business, the crypto
                and the blockchain space. Here are just a few:{' '}
                <a
                  href="https://www.press.bmwgroup.com/global/article/detail/T0328610EN/the-my-bmw-app-new-features-and-tech-insights-for-march-2021"
                  target="_blank"
                  rel="noreferrer"
                >
                  BMW
                </a>
                ,{' '}
                <a
                  href="https://squareup.com/us/en/flutter"
                  target="_blank"
                  rel="noreferrer"
                >
                  Square
                </a>
                ,{' '}
                <a
                  href="https://www.nytimes.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  The New York Times
                </a>
                ,{' '}
                <a
                  href="https://www2.mgmresorts.com/app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  MGM Resorts
                </a>
                ,{' '}
                <a
                  href="https://reflectly.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Reflectly
                </a>
                ,{' '}
                <a
                  href="https://global.toyota/en/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Toyota
                </a>
                ,{' '}
                <a href="https://ardrive.io/" target="_blank" rel="noreferrer">
                  ArDrive
                </a>
                ,{' '}
                <a
                  href="https://tech.ebayinc.com/product/ebay-motors-accelerating-with-fluttertm/"
                  target="_blank"
                  rel="noreferrer"
                >
                  eBay Motors
                </a>
                ,{' '}
                <a
                  href="https://www2.meethue.com/en-us/entertainment/sync-with-tv#get-the-app"
                  target="_blank"
                  rel="noreferrer"
                >
                  Philips
                </a>
                , and more...
              </InfoSectionItem>
              <InfoSectionHeading>Perfect for Your MVP</InfoSectionHeading>
              <InfoSectionItem>
                Do you need an MVP <i>(Minimum Viable Product)</i> to show it to
                your investors? Or get you off the ground, Flutter will be
                perfect, especially if you have little time and a limited budget
                but still require excellent UI, user experience, native
                performance, and multiple platforms.
              </InfoSectionItem>
              <InfoSectionHeading>Beautiful Widgets</InfoSectionHeading>
              <InfoSectionItem>
                Flutter provides built-in beautiful Material Design and
                Cupertino (iOS-flavor) widgets, rich motion APIs, smooth,
                natural scrolling, and platform awareness. At the same time,
                incorporating all critical platform differences such as
                scrolling, navigation, icons, and fonts to provide full native
                performance on both iOS and Android.
              </InfoSectionItem>
              <InfoSectionHeading>
                Trusted and Supported by Developers
              </InfoSectionHeading>
              <InfoSectionItem>
                In the StackOverflow survey called “Most Loved, Dreaded, and
                Wanted Other Frameworks, Libraries, and Tools,” Flutter took 3rd
                place in “Loved” and 4th place in “Wanted.” With almost 70% of
                developers declaring they are “developing with the language or
                technology and have expressed interest in continuing to develop
                with it.” In 2020 Flutter reached the highest peak of popularity
                according to Google Trends, and in January 2021, Flutter has
                more than 109,600 stars on GitHub – the most popular database of
                repositories beating out React Native! It holds position number
                16
                <i>(from over a thousand other options)</i>, with almost 20%
                more stars than other similar solutions.
              </InfoSectionItem>
              <InfoSectionHeading>Open Source</InfoSectionHeading>
              <InfoSectionItem>
                Flutter is supported by Google, a great developer community, and
                is open source under BSD 3-Clause.
              </InfoSectionItem>
            </InfoSectionList>
          </InfoSectionWrapper>
          <BottomWrapper>
            <BottomContent>
              <CTAHeading>Check Out Some of My Flutter Projects</CTAHeading>
              <CaseStudyPromoWrapper>
                <CaseStudyPromoCard
                  paintDrip
                  to="/portfolio/case-study/gili-w"
                  hex="#56AADA"
                  imageBackgroundColor="#441DC4"
                >
                  <GatsbyImage
                    objectFit="contain"
                    image={getImage(data.CaseStudy1)}
                    style={{
                      transform: 'translateX(-25%)',
                      height: '100%',
                      width: '100%',
                      borderRadius: '10%',
                      boxShadow: '0px 2px 6px #616161',
                    }}
                    imgStyle={{
                      transform: 'scale(.8)',
                    }}
                    alt=""
                  >
                    {' '}
                  </GatsbyImage>
                  <PromoCardInfoWrapper>
                    <PromoCardTitle>Gili W - Donis Health</PromoCardTitle>
                    <PromoCardText>
                      Gili W simultaneous measurement of key health indicators
                      for the heart, lungs, and overall stress using the Gil W
                      sensor and mobile app
                    </PromoCardText>
                    <PromoCardCTA
                      paintDrip
                      to="/portfolio/case-study/gili-w"
                      hex="#441DC4"
                    >
                      VIEW CASE STUDY
                    </PromoCardCTA>
                  </PromoCardInfoWrapper>
                </CaseStudyPromoCard>

                <CaseStudyPromoCard
                  paintDrip
                  to=""
                  hex="#56AADA"
                  imageBackgroundColor="#EA333B">
                  <GatsbyImage
                    objectFit="contain"
                    image={getImage(data.CaseStudy2)}
                    style={{
                      transform: 'translateX(-25%)',
                      height: '100%',
                      width: '100%',
                      borderRadius: '10%',
                      boxShadow: '0px 2px 6px #616161',
                    }}
                    imgStyle={{
                      transform: 'scale(.8)',
                    }}
                    alt=""
                  >
                    {' '}
                  </GatsbyImage>
                  <PromoCardInfoWrapper>
                    <PromoCardTitle>ArDrive - BlockChain</PromoCardTitle>
                    <PromoCardText>
                      ArDrive offers permanent, secure, always-on data storage
                      for a simple one-time price. Powedred by BlockChain
                    </PromoCardText>
                    <PromoCardCTA paintDrip to="" hex="#441DC4">
                      COMING SOON
                    </PromoCardCTA>
                  </PromoCardInfoWrapper>
                </CaseStudyPromoCard>

                <CaseStudyPromoCard
                  paintDrip
                  to="/portfolio/case-study/prospector"
                  hex="#56AADA"
                >
                  <GatsbyImage
                    objectFit="contain"
                    image={getImage(data.CaseStudy3)}
                    style={{
                      transform: 'translateX(-25%)',
                      height: '100%',
                      width: '100%',
                      borderRadius: '10%',
                      boxShadow: '0px 2px 6px #616161',
                    }}
                    imgStyle={{
                      transform: 'scale(.8)',
                    }}
                    alt=""
                  >
                    {' '}
                  </GatsbyImage>
                  <PromoCardInfoWrapper>
                    <PromoCardTitle>Prospector</PromoCardTitle>
                    <PromoCardText>
                      The day-to-day go-to sales leads platfrom for our
                      corporate sales and servicing team
                    </PromoCardText>
                    <PromoCardCTA
                      paintDrip
                      to="/portfolio/case-study/gili-w"
                      hex="#441DC4"
                    >
                      VIEW CASE STUDY
                    </PromoCardCTA>
                  </PromoCardInfoWrapper>
                </CaseStudyPromoCard>
              </CaseStudyPromoWrapper>
            </BottomContent>
          </BottomWrapper>
          <AccordionWrapper>
            <Accordion
              title="Should I use Flutter or React Native?"
              text="I believe in choosing the right technology that provides the best solution for the job at hand—saying that Flutter offers more value and performance over React Native."
            />
            <Accordion
              title="Difference between Flutter and React Native?"
              text="There are several differences between the two, but I believe the most significant difference is how each renders framework code to native code. React Native use’s a bridge while Google Flutter compiles the code back to low-level C/C++ code using the Skia Graphics engine. This provides much better performance."
            />
            <Accordion
              title="What makes Flutter unique?"
              text="Flutter is different from most other options for building mobile apps because it doesn’t rely on web browser technology nor the set of widgets that ship with each device. Instead, Flutter uses its high-performance rendering engine to draw widgets. In addition, Flutter is different because it only has a thin layer of C/C++ code."
            />
            <Accordion
              title="Is Flutter stable?"
              text="Yes, Flutter 1.0 was launched on Dec 4th, 2018, and Flutter 2 on Mar 3rd, 2021. Since then, over 100,000 apps have shipped using Flutter to many hundreds of millions of devices."
            />
            <Accordion
              title="What technology is Flutter built with?"
              text="Flutter is built with C, C++, Dart, and Skia (a 2D rendering engine). And uses Dart,a fast-growing modern language optimized for client apps. The underlying graphics framework and the Dart virtual machine are implemented in C/C++."
            />
            <Accordion
              title="Can I use Flutter for more than mobile apps?"
              text="Yes, Flutter supports mobile, web, desktop, smart devices and embed devices."
            />
          </AccordionWrapper>
          <TestimonialSection />
          <StyledContactSection />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

FlutterPage.propTypes = {
  data: PropTypes.shape({
    CaseStudy1: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
    CaseStudy2: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
    CaseStudy3: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
    flutterIcon: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
    heroBackground: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
  }),
};

export const query = graphql`
  query Images {
    seoImage: file(
      absolutePath: { regex: "/flutter/flutter-background.png/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    flutterIcon: file(absolutePath: { regex: "/flutter/flutter-logo.png/" }) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: TRACED_SVG
          tracedSVGOptions: { turnPolicy: TURNPOLICY_WHITE }
        )
      }
    }
    heroBackground: file(
      absolutePath: { regex: "/flutter/flutter-background.png/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    CaseStudy1: file(absolutePath: { regex: "/flutter/giliw-1024.png/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED )
      }
    }
    CaseStudy2: file(absolutePath: { regex: "/flutter/ardrive.png/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    CaseStudy3: file(absolutePath: { regex: "/flutter/prospector.png/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;

export default FlutterPage;
