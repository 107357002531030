import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import styled from 'styled-components';

const FaqListItem = styled('div')`
  width: 100%;
`;

const FaqListHead = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;

  padding: 16px 0;
  border-bottom: solid 2px skyblue;
`;

const FaqListTitle = styled('h2')`
  font-style: normal;
  font-weight: 400;
  color: #2f2c4c;
  width: 90%;
  margin: 0;
  font-size: 24px;
`;

const FaqDropdownWindow = styled('div')`
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.5s, opacity 0.5s;

  border-bottom: 2px solid skyblue;

  @media screen and (min-width: 1220px) {
    display: flex;
    flex-direction: row;
  }

  .active & {
    opacity: 1;
    max-height: 1500px;
  }
`;

const DropdownTextWrapper = styled('div')`
  margin: 18px 0;
  font-size: 16px;
  line-height: 1.5;
  color: #2f2c4c;
  font-family: 'roboto', sans-serif;

  @media screen and (min-width: 1220px) {
    width: 58.66%;
    margin-right: 10%;
  }
`;

const FaqItem = ({ title, text }) => {
  const listItem = useRef(null);

  const toggleDropdown = (item) => {
    item.current.classList.toggle('active');
  };

  return (
    <FaqListItem ref={listItem}>
      <FaqListHead onClick={() => toggleDropdown(listItem)}>
        <FaqListTitle>{title}</FaqListTitle>
      </FaqListHead>
      <FaqDropdownWindow>
        <DropdownTextWrapper>{text}</DropdownTextWrapper>
      </FaqDropdownWindow>
    </FaqListItem>
  );
};

FaqItem.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
};

FaqItem.defaultProps = {
  text: '',
  title: '',
};

export default FaqItem;
